import React, { useState, useEffect, useContext, useRef } from "react";
import { Button, Col, Container, Dropdown, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import Cookies from "universal-cookie";
import { navigate } from 'gatsby'
import { getAllMenu, getAllReforgedMenu } from "@/services/wogdSlice";
import { SearchContext } from "@/context/search-context";
import { MenuContext } from "@/context/menu-context";
import { Link } from "gatsby";
import { USER } from "@/services/tagFile";
import { useAddRecordMutation } from "@/services/wogdApi";
import userLogo from "../assets/svg/person-20.svg";
import { IoBagHandleOutline } from "@react-icons/all-files/io5/IoBagHandleOutline";

const HeaderOne = ({ menuData, headerDetail, screens, fullPage, activeSection, isZweihanderWeb, blogHeader, sectionThemeObj }) => {
  const menuContentObject = menuData?.length && JSON.parse(menuData?.[0]?.MenuDTO?.ContentObject)
  const navigatePath = menuData?.length && menuData[0]?.ScreenDTO?.Path?.toLowerCase()
  const [sticky, setSticky] = useState(false);
  const { searchStatus, updateSearchStatus, enterScreens } = useContext(
    SearchContext
  );
  const { menuStatus, updateMenuStatus, reforgedMenuStatus, updateReforgedMenuStatus, addScript } = useContext(MenuContext);
  const [menu, setMenu] = useState([]);
  const [logo, setLogo] = useState({});
  const [headerIcons, setHeaderIcons] = useState([]);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const myRef = useRef();
  const [addUser, { isLoading: isUserAddLoading }] = useAddRecordMutation();
  const {
    loginWithRedirect,
    isAuthenticated,
    getAccessTokenSilently,
    user,
    logout,
  } = useAuth0();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const decorIcon = {
    backgroundImage: `url(${menuContentObject?.decorIcon?.url})`
  }
  const backgroundImage = {
    backgroundImage: `url(${menuContentObject?.subMenuBackground?.url})`
  }

  useEffect(() => {
    if (!cookies.get("accessToken")) {
      if (isAuthenticated) {
        const userDetail = {
          Name: user.name,
          Description: user.Description ? user.Description : "",
          Email: user.email,
          SsoIdentifier: user.sub,
        };
        var accessToken = "";
        const getToken = async () => {
          accessToken = await getAccessTokenSilently({
            audience: process.env.GATSBY_AUTH0_AUDIENCE,
          });
          const cookies = new Cookies();
          cookies.set("accessToken", accessToken);
        };
        getToken();
        let tokenInterval = setInterval(() => {
          if (accessToken.length > 0) {
            addUser({
              entity: "User/CreateUser",
              data: userDetail,
              tag: USER,
            }).then(response => { });
            clearInterval(tokenInterval);
          }
        }, 2000);
      }
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  const handleLogout = () => {
    cookies.remove("accessToken", { path: "/" });
    logout({ returnTo: process.env.GATSBY_AUTH0_LOGIN_REDIRECT });
  };
  const handleSearchClick = e => {
    e.preventDefault();
    updateSearchStatus(!searchStatus);
  };
  const handleMenuClick = e => {
    e.preventDefault();
    updateMenuStatus(!menuStatus);
  };

  const handleScroll = () => {
    if (window.scrollY > 70) {
      setSticky(true);
    } else if (window.scrollY < 70) {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sticky]);

  useEffect(() => {
    if (headerDetail) {
      const newScript = headerDetail.find((item) => item?.ContentObject && Object.keys(JSON.parse(item?.ContentObject)).includes('script'))
      addScript(newScript)
      let headerIconRecords = headerDetail.filter(value =>
        value.ContentObject.toLowerCase().includes("icon")
      );
      // const link = JSON.parse(
      //   headerDetail.filter(val => val.FeaturedText === "Link")[0].Content
      //     .ContentObject
      // );
      // setRpgLink(link);
      setHeaderIcons(headerIconRecords);
    }
  }, [headerDetail]);

  useEffect(() => {
    if (menuContentObject?.images?.length) setLogo(menuContentObject?.images?.[0]?.url);

    if (screens) {
      enterScreens(screens);
    }
    if (menuData.length) {
      let mainMenu = [];
      menuContentObject.lists.forEach(val => mainMenu.push(val));

      if (mainMenu.length) {
        if (fullPage) dispatch(getAllReforgedMenu(mainMenu));
        else dispatch(getAllMenu(mainMenu));
        setMenu(mainMenu);
      }
    }
  }, [menuData, screens]);

  const changeActiveSection = (path) => {
    if (blogHeader) navigate(navigatePath ? `${navigatePath}#${path}` : -1)
    else window?.fullpage_api?.moveTo(path, 0)
  }

  const playAndPause = () => {
    if (!isAudioPlaying) myRef.current.play()
    else myRef.current.pause()
  }

  return (
    <header
      className={`header_01 ${sticky ? "fixedHeader animated " : ""
        } pb-2 ${fullPage ? `reforged-header ${blogHeader ? "reforged-blog-header" : ""} pt-0 ${isZweihanderWeb ? 'zweihander-header' : ''}` : 'mainHeader pt-3'} position-fixed`}
      id={`${fullPage ? 'myMenu' : 'header'}`}>
      <Container fluid>
        <Row className="justify-content-between align-items-center">
          <Col className={`col-6 ${!menuContentObject?.logo?.url ? "logo-space" : ""}`} xl={!fullPage ? 2 : 1} lg={2} md={3} sm={3}>
            <div className="logo p-0 m-0">
              {fullPage ?
                <>
                  {menuContentObject?.logo?.url && <div className="zweihander-logo" onClick={() => window.fullpage_api.moveTo("home", 0)}>
                    <img src={menuContentObject?.logo?.url} style={{ width: "100%" }} />
                  </div>}
                  <div className="menu-icon">
                    <img onClick={() => updateReforgedMenuStatus(!reforgedMenuStatus)} src={menuContentObject?.menuIcon?.url} />
                  </div>
                </> :
                (logo && (
                  <Link to={'/'}>
                    <img
                      src={logo}
                      alt=""
                    />
                  </Link>
                ))
              }
            </div>
          </Col>
          <Col xl={!fullPage ? 8 : 10} lg={!fullPage ? 8 : 10} sm={8} md={7} className="d-none d-lg-block">
            <nav className={`mainmenu ${!fullPage ? 'text-center' : ''}`}>
              <ul>
                {menu.map((links, index) => {
                  return (
                    <li
                      key={index}
                      className={`pb-0 ${links?.subMenu.length ? "menu-item-has-children" : ""} ${fullPage && (activeSection === links?.name.toLowerCase()) || (links?.subMenu.length && links?.subMenu.some(item => item?.name.toLowerCase() === activeSection)) ? 'active' : ''} ${fullPage ? 'reforged-theme' : ''}`}
                      onClick={() => changeActiveSection(links?.path.slice(1, links.path.length).toLowerCase())}
                    >
                      {fullPage ? (links?.href) ? (<>
                        <a className="menu-name link-menu-name" style={{ color: sectionThemeObj?.menuFont || "" }} href={links?.href} target={links?.target}>
                          {links?.name}
                        </a>
                        <span className="hover-guang"></span>
                      </>
                      ) : <>
                        <span className="menu-name" style={{ color: sectionThemeObj?.menuFont || "" }}> {links?.name.toUpperCase()}</span>
                        <span className="icon"></span>
                        <span className="hover-guang"></span>
                      </> : (links?.href) ? (
                        <a href={links?.href} target={links?.target}>
                          {links?.name}
                        </a>
                      ) : (
                        <Link to={links?.path}>{links?.name}</Link>
                      )}
                      {links?.subMenu?.length ? fullPage ?
                        <div className="reforged-dropdown" style={backgroundImage}>
                          <div className="bg_box">
                            <div className="top"></div>
                            {links?.subMenu.map((subLinks, index) => (
                              <a key={index} href={subLinks?.href || subLinks?.path || '#'} target={subLinks?.href ? "_blank" : ''}>
                                <p onClick={() => changeActiveSection(subLinks?.name.toLowerCase())}>
                                  <span className="icon" style={decorIcon}></span>
                                  <span className="reforged-submenu" style={{ color: sectionThemeObj?.subMenuFont || "" }}>{subLinks?.name.toUpperCase()}</span>
                                </p>
                              </a>
                            ))}
                          </div>
                        </div>
                        : (
                          <ul className="sub-menu">
                            {links?.subMenu.map((subLinks, index) => {
                              return (
                                <li key={index} className={`${subLinks?.subMenu.length ? 'menu-children-has-children' : ''}`}>
                                  {subLinks?.href ? (
                                    <a
                                      href={subLinks?.href}
                                      target={"_blank"}
                                    >
                                      {subLinks?.name}
                                    </a>
                                  ) : (
                                    <Link to={subLinks?.path}>
                                      {subLinks?.name}
                                    </Link>
                                  )}
                                  {subLinks?.subMenu.length ?
                                    <ul className="third-level-sub-menu">
                                      {subLinks?.subMenu.map((nestedLinks, index) => {
                                        return (
                                          <li key={index}>
                                            {nestedLinks?.href ? (
                                              <a
                                                href={nestedLinks?.href}
                                                target={"_blank"}
                                              >
                                                {nestedLinks?.name}
                                              </a>
                                            ) : (
                                              <Link to={nestedLinks?.path}>
                                                {nestedLinks?.name}
                                              </Link>
                                            )}
                                          </li>
                                        )
                                      })}
                                    </ul> : ''
                                  }
                                </li>
                              );
                            })}
                          </ul>
                        ) : null}
                    </li>
                  );
                })}
              </ul>
            </nav>
          </Col>
          <Col xl={!fullPage ? 2 : 1} lg={!fullPage ? 2 : 1} md={2} sm={4} className="col-6">
            {fullPage ? menuContentObject?.backgroundMusic?.url &&
              <>
                <div className="d-flex justify-content-end">
                  <img src={menuContentObject?.pauseAudioIcon?.url} onClick={() => {
                    setIsAudioPlaying(!isAudioPlaying)
                    playAndPause()
                  }} style={{ ...(isAudioPlaying && { display: "none" }) }} />
                  <img src={menuContentObject?.playAudioIcon?.url} onClick={() => {
                    setIsAudioPlaying(!isAudioPlaying)
                    playAndPause()
                  }} style={{ ...(!isAudioPlaying && { display: "none" }) }} />
                </div>
                <audio loop ref={myRef} src={menuContentObject?.backgroundMusic?.url} />
              </>
              : <div className="navigator text-right action_button py-0 align-items-center">
                {headerIcons.length > 0 ?
                  headerIcons.map((data, index) =>
                    data.ContentObject.toLowerCase().includes("icon") && (
                      <a
                        key={index}
                        className={
                          handleSearchClick
                            ? "search searchToggler"
                            : "menu hamburger"
                        }
                        href="#"
                        onClick={e => {
                          switch (JSON.parse(data?.ContentObject)?.callToAction) {
                            case "handleSearchClick":
                              return handleSearchClick(e);
                            case "handleMenuClick":
                              return handleMenuClick(e);
                            default:
                              break;
                          }
                        }}
                      >
                        <i className={JSON.parse(data?.ContentObject)?.icon}></i>
                      </a>
                    )
                  ) : ''}
                {menuContentObject?.storeTitle && menuContentObject?.storeLink ? (
                  <>
                    <a
                      href={menuContentObject?.storeLink}
                      className="search d-block d-lg-none"
                    >
                      <IoBagHandleOutline
                        style={{
                          width: "1.8rem",
                          height: "1.8rem",
                        }}
                      />
                    </a>
                    <a
                      href={menuContentObject?.storeLink}
                      className="search d-none d-lg-block"
                      style={{ fontSize: "1rem" }}
                    >
                      {menuContentObject?.storeTitle}
                    </a>
                  </>
                ) : ''
                }
                {/* {!!cookies.get("accessToken") ? (
                <Dropdown>
                  <Dropdown.Toggle variant="light" id="dropdown-basic">
                    <img src={userLogo}></img>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      className="profile_item"
                      onClick={() => handleLogout()}
                    >
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <Button
                  className="auth_button"
                  variant="light"
                  onClick={() =>
                    loginWithRedirect({
                      appState: {
                        returnTo: window.location.pathname,
                      },
                    })
                  }
                >
                  Login
                </Button>
              )} */}
              </div>}
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default HeaderOne;
